import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { RouteLogement } from "./RouteLogement";
import { RouteBureau } from "./RouteBureau";

import PrivateRoute from "./PrivateRoute";
import ConnexionTrueRoute from "./ConnexionTrueRoute";

// Lazy-loaded Pages
const Home = lazy(() => import("../pages/public/vitrine/Home"));
const Presentation = lazy(() => import("../pages/public/vitrine/Presentation"));
const Etudes = lazy(() => import("../pages/public/vitrine/Etudes"));
const Abonner = lazy(() => import("../pages/public/vitrine/Abonner"));
const ContactPage = lazy(() => import("../pages/public/vitrine/ContactPage"));
const Plan = lazy(() => import("../pages/public/vitrine/Plan"));
const Mentions = lazy(() => import("../pages/public/vitrine/Mentions"));
const Politiques = lazy(() => import("../pages/public/vitrine/Politiques"));
const Devarea = lazy(() => import("../pages/Devarea"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Connexion = lazy(() => import("../pages/public/auth/Connexion"));
const UserPage = lazy(() => import("../pages/private/UserPage"));
const SocioPdf = lazy(() =>
  import("../pages/private/Logement/SocioDemographique/Pdf/SocioPdf")
);
const CreateUserPage = lazy(() => import("../pages/private/CreateUserPage"));

function RouteWatcher() {
  const [myHistory, setMyHistory] = useState([]);

  const location = useLocation();
  useEffect(() => {
    let history = [...myHistory, location.pathname];
    let historySlice = history.slice(-2);
    setMyHistory(historySlice);
    localStorage.setItem("lastLocation", JSON.stringify(historySlice));
  }, [location]);

  return null;
}

export default function AllRoutes() {
  return (
    <>
      {/* External stylesheets */}
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"
      />
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"
      />

      <BrowserRouter>
        <RouteWatcher />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Home />} />

          <Route
            path="/se_connecter"
            element={
              <ConnexionTrueRoute>
                <Connexion />
              </ConnexionTrueRoute>
            }
          />

          <Route path="/presentation" element={<Presentation />} />
          <Route path="/etudes" element={<Etudes />} />
          <Route path="/abonner" element={<Abonner />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/mentions" element={<Mentions />} />
          <Route path="/politiques" element={<Politiques />} />

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />

          {/* Development area */}
          <Route path="/devarea" element={<Devarea />} />

          {/* Private routes for Logement */}
          {RouteLogement.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute route={route.path}>{route.element}</PrivateRoute>
              }
            />
          ))}

          {/* Private routes for Bureau */}
          {RouteBureau.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute route={route.path}>{route.element}</PrivateRoute>
              }
            />
          ))}

          {/* Private route for user profile */}
          <Route
            path="/mon-profil"
            element={
              <PrivateRoute route="/mon-profil" access={true}>
                <UserPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-user"
            element={
              <PrivateRoute route="/create-user" access={true}>
                <CreateUserPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/logement/donnees-socio-demographique/pdf"
            element={
              <PrivateRoute route="/logement/donnees-socio-demographique/pdf">
                <SocioPdf />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
