import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContextProvider";

const ConnexionTrueRoute = ({ children }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => setScreenSize(window.innerWidth);

  // Get the authentication status from the context
  const { isAuth } = useContext(AuthContext);

  useEffect(() => {
    if (screenSize > 1050) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  // If authenticated, redirect to the dashboard
  if (isAuth) {
    return <Navigate to="/logement/dashboard" replace />;
  }
  if (isMobile) {
    return <Navigate to="/" replace />;
  }

  // If not authenticated, render the provided children
  return children;
};

export default ConnexionTrueRoute;
