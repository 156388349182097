import React, { lazy } from "react";

import ficheProg from "../assets/icons/modules/bureaux/icon_fiche_programmes.svg";
import bookProg from "../assets/icons/modules/bureaux/icon_book_programmes.svg";
import analyseAnnuelle from "../assets/icons/modules/bureaux/icon_analyse_annuelle.svg";
import tableauxStatistiques from "../assets/icons/modules/bureaux/icon_tableaux_statistiques.svg";
import noteSynthese from "../assets/icons/modules/bureaux/icon_note_synthese.svg";
import diaporama from "../assets/icons/modules/bureaux/icon_diaporama_marche.svg";
import methodologie from "../assets/icons/modules/logements/icon_methodologie.svg";

const FicheProgrammeBureau = lazy(() =>
  import("../pages/private/Bureau/FicheProgrammeBureau")
);
const BookProgBureau = lazy(() =>
  import("../pages/private/Bureau/BookProgBureau")
);
const AnalyseAnnuelleBureau = lazy(() =>
  import("../pages/private/Bureau/AnalyseAnnuelleBureau")
);
const TableauxStatistiquesBureau = lazy(() =>
  import("../pages/private/Bureau/TableauxStatistiquesBureau")
);
const NoteSyntheseBureau = lazy(() =>
  import("../pages/private/Bureau/NoteSyntheseBureau")
);
const DiaporamaBureau = lazy(() =>
  import("../pages/private/Bureau/DiaporamaBureau")
);
const MéthodologieBureau = lazy(() =>
  import("../pages/private/Bureau/MéthodologieBureau")
);

export const RouteBureau = [
  {
    icon: ficheProg,
    name: "Fiches programmes",
    path: "/bureau/programme",
    current: false,
    element: <FicheProgrammeBureau />,
  },
  {
    icon: bookProg,
    name: "Book programmes",
    path: "/bureau/book-programme",
    current: false,
    element: <BookProgBureau />,
  },
  {
    icon: analyseAnnuelle,
    name: "Analyse annuelle",
    path: "/bureau/analyse",
    current: false,
    element: <AnalyseAnnuelleBureau />,
  },
  {
    icon: tableauxStatistiques,
    name: "Tableaux statistiques",
    path: "/bureau/tableaux",
    current: false,
    element: <TableauxStatistiquesBureau />,
  },
  {
    icon: noteSynthese,
    name: "Note de synthèse",
    path: "/bureau/synthese",
    current: false,
    element: <NoteSyntheseBureau />,
  },
  {
    icon: diaporama,
    name: "Diaporama",
    path: "/bureau/diaporama",
    current: false,
    element: <DiaporamaBureau />,
  },
  {
    icon: methodologie,
    name: "Méthodologie & définitions",
    path: "/bureau/definitions",
    current: false,
    element: <MéthodologieBureau />,
  },
];
