import React, { createContext, useEffect, useState, useMemo } from "react";

export const SearchContext = createContext();

const SearchContextProvider = ({ children }) => {
  const [commune, setCommune] = useState(
    JSON.parse(localStorage.getItem("commune"))
  );
  const [logement, setLogement] = useState(
    JSON.parse(localStorage.getItem("logement"))
  );
  const [typeProgramme, setTypeProgramme] = useState(
    JSON.parse(localStorage.getItem("typeProgramme"))
  );
  const [typeTva, setTypeTva] = useState(
    JSON.parse(localStorage.getItem("typeTva"))
  );
  const [pkData, setPkData] = useState(
    JSON.parse(localStorage.getItem("pkData"))
  );
  const [zoneBureau, setZoneBureau] = useState(
    JSON.parse(localStorage.getItem("zoneBureau"))
  );
  const [typeConstruction, setTypeConstruction] = useState(
    JSON.parse(localStorage.getItem("typeConstruction"))
  );
  const [typeAvancement, setTypeAvancement] = useState(
    JSON.parse(localStorage.getItem("typeAvancement"))
  );
  const [tailleOp, setTailleOp] = useState(
    JSON.parse(localStorage.getItem("tailleOp"))
  );
  const [typeDev, setTypeDev] = useState(
    JSON.parse(localStorage.getItem("TypeDev"))
  );

  useEffect(() => {
    localStorage.setItem("commune", JSON.stringify(commune));
    localStorage.setItem("logement", JSON.stringify(logement));
    localStorage.setItem("typeProgramme", JSON.stringify(typeProgramme));
    localStorage.setItem("typeTva", JSON.stringify(typeTva));
    localStorage.setItem("pkData", JSON.stringify(pkData));
    localStorage.setItem("zoneBureau", JSON.stringify(zoneBureau));
    localStorage.setItem("typeConstruction", JSON.stringify(typeConstruction));
    localStorage.setItem("typeAvancement", JSON.stringify(typeAvancement));
    localStorage.setItem("tailleOp", JSON.stringify(tailleOp));
    localStorage.setItem("typeDev", JSON.stringify(typeDev));
  }, [
    commune,
    logement,
    typeProgramme,
    typeTva,
    pkData,
    zoneBureau,
    typeConstruction,
    typeAvancement,
    tailleOp,
    typeDev,
  ]);

  const providerValue = useMemo(
    () => ({
      commune,
      setCommune,
      logement,
      setLogement,
      typeProgramme,
      setTypeProgramme,
      typeTva,
      setTypeTva,
      pkData,
      setPkData,
      zoneBureau,
      setZoneBureau,
      typeConstruction,
      setTypeConstruction,
      typeAvancement,
      setTypeAvancement,
      tailleOp,
      setTailleOp,
      typeDev,
      setTypeDev,
    }),
    [
      commune,
      logement,
      typeProgramme,
      typeTva,
      pkData,
      zoneBureau,
      typeConstruction,
      typeAvancement,
      tailleOp,
      typeDev,
    ]
  );

  return (
    <SearchContext.Provider value={providerValue}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
