import React, { lazy } from "react";

import home from "../assets/icons/modules/logements/icon_home.svg";
import progTranche from "../assets/icons/modules/logements/icon_programmes_tranches.svg";
import exportGlobal from "../assets/icons/modules/logements/icon_export_global.svg";
import donneeSocio from "../assets/icons/modules/logements/icon_donnee_socio.svg";
import etatMarche from "../assets/icons/modules/logements/icon_etat_marche.svg";
import permis from "../assets/icons/modules/logements/icon_permis.svg";
import conjoncture from "../assets/icons/modules/logements/icon_conjoncture.svg";
import methodologie from "../assets/icons/modules/logements/icon_methodologie.svg";
import reunion from "../assets/icons/modules/logements/icon_reunion.svg";

const DashboardLogement = lazy(() =>
  import("../pages/private/Logement/DashboardLogement")
);
// Programme Start
const PtHome = lazy(() =>
  import("../pages/private/Logement/ProgrammeTrache/PtHome")
);
// Programme End
const PtDetail = lazy(() =>
  import("../pages/private/Logement/ProgrammeTrache/PtDetail")
);
const PtMapPdf = lazy(() =>
  import("../pages/private/Logement/ProgrammeTrache/Pdf/PtMapPdf")
);
// Sociodemographique Start
const SdHome = lazy(() =>
  import("../pages/private/Logement/SocioDemographique/SdHome")
);
const SdPdf = lazy(() =>
  import("../pages/private/Logement/SocioDemographique/SdPdf")
);
// Sociodemographique End

const EgHome = lazy(() =>
  import("../pages/private/Logement/ExportGlobal/EgHome")
);
const PcHome = lazy(() =>
  import("../pages/private/Logement/PermisContruire/PcHome")
);

// Conjoncture Start
const CjHome = lazy(() =>
  import("../pages/private/Logement/Conjoncture/CjHome")
);
const DonneeContentPdf = lazy(() =>
  import("../pages/private/Logement/Conjoncture/Pdf/DonneeContentPdf")
);
const DonneePrixContentPdf = lazy(() =>
  import("../pages/private/Logement/Conjoncture/Pdf/DonneePrixContentPdf")
);
const DonneeTriContentPdf = lazy(() =>
  import("../pages/private/Logement/Conjoncture/Pdf/DonneeTriContentPdf")
);
// Conjoncture End

// Methodologie Start
const MethodeDef = lazy(() =>
  import("../pages/private/Logement/Definitions/MethodeDef")
);
// Methodologie End
const ReuHome = lazy(() =>
  import("../pages/private/Logement/Reunion/ReuHome")
);
const EgPdf = lazy(() =>
  import("../pages/private/Logement/ExportGlobal/EgPdf")
);

const EmHome = lazy(() =>
  import("../pages/private/Logement/EtatMarche/EmHome")
);

const EmDataCompare = lazy(() =>
  import("../pages/private/Logement/EtatMarche/EmDataCompare")
);

const SocioPdf = lazy(() =>
  import("../pages/private/Logement/SocioDemographique/Pdf/SocioPdf")
);

const PtDetailMapPdf = lazy(() =>
  import("../pages/private/Logement/ProgrammeTrache/Pdf/PtDetailMapPdf")
);

const EmPdf = lazy(() =>
  import("../pages/private/Logement/EtatMarche/Pdf/EmPdf")
);

const DetailFicheProgrammeBureauPdf = lazy(() =>
  import("../pages/private/Bureau/DetailFicheProgrammeBureauPdf")
);

const DetailFicheProgrammeBureau = lazy(() =>
  import("../pages/private/Bureau/DetailFicheProgrammeBureau")
);


export const RouteLogement = [
  {
    name: "Accueil",
    path: "/logement/dashboard",
    current: false,
    icon: home,
    element: <DashboardLogement />,
  },
  {
    name: "Programmes & tranches",
    path: "/logement/programme",
    current: false,
    icon: progTranche,
    element: <PtHome />,
  },
  {
    name: "Etat du marché",
    path: "/logement/etat-du-marche",
    current: false,
    icon: etatMarche,
    element: <EmHome />,
  },
  {
    name: "Données socio-démographiques & économiques",
    path: "/logement/donnees-socio-demographique",
    current: false,
    icon: donneeSocio,
    element: <SdHome />,
  },
  {
    name: "Données socio-démographiques & économiques",
    path: "/logement/donnees-socio-demographique/pdf",
    current: false,
    icon: false,
    element: <SdPdf />,
  },
  {
    name: "PtDetail",
    path: "/logement/programme/:id",
    current: false,
    icon: false,
    element: <PtDetail />,
  },
  {
    name: "DetailFicheProgrammeBureauPdf",
    path: "/bureau/programme-detail/pdf/:id",
    current: false,
    icon: false,
    element: <DetailFicheProgrammeBureauPdf />,
  },
  {
    name: "DetailFicheProgrammeBureau",
    path: "/bureau/programme-detail/:id",
    current: false,
    icon: false,
    element: <DetailFicheProgrammeBureau />,
  },
  {
    name: "Export global",
    path: "/logement/export-global",
    current: false,
    icon: exportGlobal,
    element: <EgHome />,
  },
  {
    name: "Permis de construire",
    path: "/logement/permis",
    current: false,
    icon: permis,
    element: <PcHome />,
  },
  {
    name: "EmDataCompare",
    path: "/logement/etat-du-marche/data/:id",
    current: false,
    icon: false,
    element: <EmDataCompare />,
  },
  {
    name: "Conjoncture",
    path: "/logement/conjoncture",
    current: false,
    icon: conjoncture,
    element: <CjHome />,
  },
  {
    name: "DonneeContentPdf",
    path: "/logement/conjoncture/donnee-pdf",
    current: false,
    icon: false,
    element: <DonneeContentPdf />,
  },
  {
    name: "DonneeTriContentPdf",
    path: "/logement/conjoncture/donnee-tri-pdf/:type",
    current: false,
    icon: false,
    element: <DonneeTriContentPdf />,
  },
  {
    name: "DonneePrixContentPdf",
    path: "/logement/conjoncture/donnee-prix-pdf",
    current: false,
    icon: false,
    element: <DonneePrixContentPdf />,
  },
  {
    name: "Méthodologie & définitions",
    path: "/logement/definitions",
    current: false,
    icon: methodologie,
    element: <MethodeDef />,
  },
  {
    name: "Réunions",
    path: "/logement/reunions",
    current: false,
    icon: reunion,
    element: <ReuHome />,
  },
  {
    name: "EgPdf",
    path: "/logement/export-global/pdf",
    current: false,
    icon: false,
    element: <EgPdf />,
  },
  {
    name: "SocioPdf",
    path: "/logement/programme/pdf/map/:id",
    current: false,
    icon: false,
    element: <PtMapPdf />,
  },
  {
    name: "PtDetailMapPdf",
    path: "/logement/programme-detail/pdf/:id",
    current: false,
    icon: false,
    element: <PtDetailMapPdf />,
  },
  {
    name: "EmPdf",
    path: "/logement/etat-du-marche/pdf/:id",
    current: false,
    icon: false,
    element: <EmPdf />,
  },
];
