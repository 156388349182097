import React, { Suspense } from "react";
import SearchContextProvider from "./context/SearchContextProvider";
import AuthContextProvider from "./context/AuthContextProvider";
import DefaultLoading from "./components/Ui/Other/DefaultLoading";
import AllRoutes from "./routes/AllRoutes";

export default function App() {
  return (
    <AuthContextProvider>
      <SearchContextProvider>
        <Suspense
          fallback={
            <div className="flex bg-[#EFF8FC] h-[100vh] w-full">
              <DefaultLoading />
            </div>
          }
        >
          <AllRoutes />
        </Suspense>
      </SearchContextProvider>
    </AuthContextProvider>
  );
}
