import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContextProvider";
import { SearchContext } from "../context/SearchContextProvider";
import InfoSizeScreen from "../components/InfoSizeScreen";

const PrivateRoute = ({ children, route, access }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => setScreenSize(window.innerWidth);
  access = access || false;

  // Get the authentication status from the context
  const { isAuth, user } = useContext(AuthContext);

  // Get the search status from the context
  const { commune } = useContext(SearchContext);

  let accessLogement = false;
  let accessBureau = false;

  useEffect(() => {
    if (screenSize > 1050) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  if (user === null || user.acces === null) {
    return <Navigate to="/se_connecter" replace />;
  }
  switch (user.acces) {
    case 1:
      accessLogement = true;
      accessBureau = true;
      break;
    case 2:
      accessLogement = false;
      accessBureau = true;
      break;
    case 3:
      accessLogement = true;
      accessBureau = false;
      break;
    default:
      accessLogement = false;
      accessBureau = false;
  }

  // If not authenticated, redirect to the login page
  if (!isAuth) {
    return <Navigate to="/se_connecter" replace />;
  } else if (
    accessLogement === false &&
    accessBureau === true &&
    route.split("/")[1] === "logement" &&
    route !== "/logement/dashboard"
  ) {
    return <Navigate to="/bureau/programme" replace />;
  } else if (
    accessLogement === true &&
    accessBureau === false &&
    route.split("/")[1] === "bureau"
  ) {
    return <Navigate to="/logement/dashboard" replace />;
  } else if (
    commune === null &&
    route !== "/logement/dashboard" &&
    !access &&
    route !== "/logement/conjoncture" &&
    route !== "/logement/export-global" &&
    route.split("/")[1] !== "bureau" &&
    route !== "/logement/definitions" &&
    route !== "/logement/reunions" &&
    route !== "/logement/etat-du-marche"
  ) {
    return <Navigate to="/logement/dashboard" replace />;
  }

  // If authenticated, render the provided children
  return isMobile ? <InfoSizeScreen /> : children;
};

export default PrivateRoute;
