import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};
const AuthContextProvider = ({ children }) => {
  // Vos fonctions et état d'authentification ici

  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [tokenExpiration, setTokenExpiration] = useState(
    JSON.parse(localStorage.getItem("tokenExpiration"))
  );
  const [isBureau, setIsBureau] = useState(
    JSON.parse(localStorage.getItem("isBureau"))
  );
  const [isLogement, setIsLogement] = useState(
    JSON.parse(localStorage.getItem("isLogement"))
  );

  useEffect(() => {
    localStorage.setItem("isAuth", JSON.stringify(isAuth));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("tokenExpiration", JSON.stringify(tokenExpiration));
    localStorage.setItem("isBureau", JSON.stringify(isBureau));
    localStorage.setItem("isLogement", JSON.stringify(isLogement));
  }, [user, token, tokenExpiration, isBureau, isLogement, isAuth]);

  useEffect(() => {
    if (tokenExpiration && new Date() > new Date(tokenExpiration)) {
      // Le token a expiré, déconnectez l'utilisateur
      setIsAuth(false);
      setUser(null);
      setToken(null);
      setTokenExpiration(null);
      setIsBureau(false);
      setIsLogement(false);
    }
  }, [tokenExpiration]);

  const providerValue = useMemo(
    () => ({
      isAuth,
      setIsAuth,
      user,
      setUser,
      token,
      setToken,
      tokenExpiration,
      setTokenExpiration,
      isBureau,
      setIsBureau,
      isLogement,
      setIsLogement,
    }),
    [isAuth, user, token, tokenExpiration, isBureau, isLogement]
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
